<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="!basketIsEmpty"
    :full-width-title="basketIsEmpty"
  >
    <!-- Title -->
    <template #title>
      <title-text
        :title="$t('pages.order.basket.subtitle')"
        :status="basket.status"
        :no-data="basketIsEmpty"
      />
    </template>
    <div v-if="!basketIsEmpty">
      <el-row :gutter="20" class="mb-8">
        <el-col :md="3">
          <div class="flex flex-col">
            <span class="u-table-section-subtitle">{{
              $t('pages.orders.basket.detail.headers.basket_id')
            }}</span>
            <span class="u-table-section-data">{{ basket.basketId }}</span>
          </div>
        </el-col>
        <el-col :md="3">
          <div class="flex flex-col">
            <span class="u-table-section-subtitle w-48">{{
              $t('pages.orders.basket.detail.headers.gross_value')
            }}</span>
            <span class="u-table-section-data">{{
              $formatCurrency(basket.totalGrossValue, basket.currency)
            }}</span>
          </div>
        </el-col>
        <el-col :md="3">
          <div class="flex flex-col">
            <span class="u-table-section-subtitle">{{
              $t('pages.orders.basket.detail.headers.discount')
            }}</span>
            <span class="u-table-section-data">{{
              $formatCurrency(basket.discount, basket.currency)
            }}</span>
          </div>
        </el-col>
        <el-col :md="3">
          <div class="flex flex-col">
            <span class="u-table-section-subtitle">{{
              $t('pages.orders.basket.detail.headers.total')
            }}</span>
            <span class="u-table-section-data">{{
              $formatCurrency(basket.total, basket.currency)
            }}</span>
          </div>
        </el-col>
        <el-col :md="3">
          <div class="flex flex-col">
            <span class="u-table-section-subtitle">{{
              $t('pages.orders.basket.detail.headers.tax')
            }}</span>
            <span class="u-table-section-data">{{
              $formatCurrency(basket.tax, basket.currency)
            }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <th-table
          :data="tableData"
          :columns="columns"
          show-total
          show-checkbox
          disable-pagination
          :no-data-text="
            $t('pages.products.edit.form.sections.variant_stock.table.no_data')
          "
          id-field="itemId"
          class="w-full"
          @selection-change="handleSelectionChange"
        />
      </el-row>
      <el-row class="my-8">
        <el-button
          class="uppercase action-button"
          :class="{ 'is-disabled': !selectedItems.length }"
          plain
        >
          {{ $t('pages.orders.detail.actions.refund') }}
        </el-button>
      </el-row>
    </div>
  </th-wrapper>
</template>

<script>
import ThTable from '@/components/table'
import titleText from '../../components/title-text'
export default {
  components: {
    ThTable,
    titleText
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      selectedItems: [],
      columns: [
        {
          label: this.$t('pages.orders.basket.item.detail.headers.id'),
          key: 'itemId',
          visible: true,
          formatter: (value) => value.itemId || this.notDefined
        },
        {
          label: this.$t('pages.orders.basket.item.detail.headers.name'),
          key: 'name',
          visible: true,
          formatter: (value) => value.name || this.notDefined
        },
        {
          label: this.$t('pages.orders.basket.item.detail.headers.description'),
          key: 'descriptor',
          visible: true,
          formatter: (value) => value.descriptor || this.notDefined
        },
        {
          label: this.$t('pages.orders.basket.item.detail.headers.price'),
          key: 'price',
          visible: true,
          class: 'text-right',
          total: () =>
            this.$formatCurrency(
              this.basket.totalGrossValue,
              this.basket.currency
            ),
          formatter: (value) =>
            this.$formatCurrency(value.amountPerUnit, this.basket.currency) ||
            this.notDefined
        },
        {
          label: this.$t('pages.orders.basket.item.detail.headers.quantity'),
          key: 'quantity',
          visible: true,
          class: 'text-right',
          total: () => this.basket.quantity,
          formatter: (value) => value.quantity || this.notDefined
        },
        {
          label: this.$t('pages.orders.basket.item.detail.headers.discount'),
          key: 'discount',
          visible: true,
          class: 'text-right',
          total: () =>
            this.$formatCurrency(this.basket.discount, this.basket.currency),
          formatter: (value) =>
            this.$formatCurrency(value.discount, this.basket.currency) ||
            this.notDefined
        },
        {
          label: this.$t('pages.orders.basket.item.detail.headers.total'),
          key: 'total',
          visible: true,
          class: 'text-right',
          total: () =>
            this.$formatCurrency(this.basket.total, this.basket.currency),
          formatter: (value) =>
            this.$formatCurrency(value.totalAmount, this.basket.currency) ||
            this.notDefined
        },
        {
          label: this.$t('pages.orders.basket.item.detail.headers.vat'),
          key: 'vatPercentage',
          visible: true,
          formatter: (value) => value.vatPercentage || this.notDefined
        },
        {
          label: this.$t('pages.orders.basket.item.detail.headers.tax'),
          key: 'tax',
          visible: true,
          class: 'text-right',
          total: () =>
            this.$formatCurrency(this.basket.tax, this.basket.currency),
          formatter: (value) =>
            this.$formatCurrency(value.tax, this.basket.currency) ||
            this.notDefined
        },
        {
          label: this.$t('pages.orders.basket.item.detail.headers.status'),
          key: 'status',
          componentType: 'status',
          visible: true,
          class: 'text-right',
          formatter: (value) => value.status || this.notDefined
        },
        {
          label: this.$t('pages.orders.basket.item.detail.headers.image_url'),
          key: 'imageUrl',
          visible: true,
          class: 'text-right',
          formatter: (value) => value.imageUrl || this.notDefined
        }
      ]
    }
  },

  computed: {
    basket() {
      return this.modelValue?.basket || {}
    },
    tableData() {
      return this.modelValue?.basket?.items || []
    },
    basketIsEmpty() {
      return Object.keys(this.basket).length === 0
    },
    notDefined() {
      return this.$t('common.orders.not_defined')
    }
  },

  methods: {
    handleSelectionChange(val) {
      this.selectedItems = val
    }
  }
}
</script>
<style scoped>
.action-button {
  background-color: var(--button-basket-color);
}
</style>
