<template>
  <div
    class="payment-card p-8 m-4 mb-3 cursor-pointer"
    :class="{ selected: selected }"
  >
    <!-- Title -->
    <el-row :gutter="20">
      <el-col :md="18">
        <div class="w-1/2 mb-2 flex">
          <span class="u-card-title place-self-center">{{
            transactionTypes[transaction.transactionType] || ''
          }}</span>
          <status-text :status="transaction.processingResult" />
        </div>
        <div
          class="text-th-label-text u-body-details u-body-detail-holder mb-0"
        >
          {{ transaction.requestTimestamp }}
        </div>
      </el-col>
      <el-col :md="6" class="text-right place-self-center">
        <span class="u-card-title">{{
          transaction?.paymentPresentationAmount?.amount
            ? $formatCurrency(
                transaction?.paymentPresentationAmount?.amount,
                transaction.paymentPresentationCurrency
              )
            : '-'
        }}</span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import statusText from '../../components/status-text'
import orderSetup from '../../constants/order'
export default {
  components: {
    statusText
  },
  props: {
    transaction: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const orderConst = orderSetup.setup()
    const transactionTypes = {}
    orderConst.transactionTypes.forEach((type) => {
      transactionTypes[type.value] = type.label
    })
    return {
      transactionTypes
    }
  }
}
</script>
<style scoped>
.payment-card {
  min-height: 112px;
  max-width: 317px;
  border-radius: 4px;
  border: 2px solid var(--input-border-color);
}

.selected {
  border-color: var(--primary-color);
  background: var(--primary-color-hover);
}
</style>
