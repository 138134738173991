<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="!attachmentIsEmpty"
    :full-width-title="attachmentIsEmpty"
  >
    <!-- Title -->
    <template #title>
      <title-text
        :title="$t('pages.order.attachment.subtitle')"
        :no-data="attachmentIsEmpty"
      />
    </template>
    <div v-if="!attachmentIsEmpty">
      <el-row
        v-for="(attachment, index) in attachments"
        :key="index"
        class="border-b mb-5"
      >
        <el-col :md="17" class="flex items-center">
          <span class="text-th-primary"
            ><a target="_blank" :href="attachment.url">{{
              attachment.fileName
            }}</a></span
          >
        </el-col>
        <el-col :md="3" class="text-center flex items-center"
          ><span class="w-full">{{ attachment.uploadedOn }}</span></el-col
        >
        <el-col :md="3" class="text-center flex items-center"
          ><span class="attach-type">{{ attachment.type }}</span></el-col
        >
        <el-col :md="1" class=""
          ><el-button
            text
            class="focus:outline-none focus:shadow-outline p-0 w-full text-right action-button"
          >
            <a target="_blank" :href="attachment.url">
              <svgicon
                :src="require('@/assets/icons/th-icon-download.svg')"
                :style="{
                  height: '23px',
                  width: '23px'
                }"
                class="fill-current"
            /></a> </el-button
        ></el-col>
      </el-row>
    </div>
  </th-wrapper>
</template>

<script>
import titleText from '../../components/title-text'
export default {
  components: {
    titleText
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    attachments() {
      let attachments = []
      this.modelValue?.dispute?.forEach(
        (dispute) => (attachments = attachments.concat(dispute.attachments))
      )
      return attachments
    },
    attachmentIsEmpty() {
      return (this.attachments || []).length === 0
    }
  }
}
</script>
<style scoped>
.attach-type {
  background-color: var(--background-color);
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
}

.action-button {
  color: var(--button-text-color);
}
</style>
