<template>
  <th-wrapper body-class="p-8 pb-3" full-width-title navigate-back-button>
    <!-- Title -->
    <template #title>
      <div class="w-1/2 mb-2 flex">
        <span class="u-title-section"
          >{{ $t('pages.order.subtitle') }} {{ order.orderId }}</span
        >
        <origin-text :origin="order.origin" />
        <status-text :status="order.status" />
      </div>
      <span class="w-1/2 text-right u-title-section mb-2">
        {{ $formatCurrency(order.totalAmount, order.currency) }}
      </span>
    </template>
    <el-row :gutter="20" class="mb-8">
      <el-col :md="4">
        <div class="flex flex-col">
          <span class="u-table-section-subtitle">{{
            $t('pages.orders.detail.headers.last_updated')
          }}</span>
          <span class="u-table-section-data">{{ order.updatedAt }}</span>
        </div>
      </el-col>
      <el-col :md="4">
        <div class="flex flex-col">
          <span class="u-table-section-subtitle w-48">{{
            $t('pages.orders.detail.headers.payment_methods')
          }}</span>
          <span
            class="text-th-primary cursor-pointer flex u-table-section-data"
            @click="$emit('scroll-to', 'payment')"
          >
            <origin-payment
              :origin="(order?.payments || [])[0]?.brand"
              class="mr-2"
            />
            <span class="place-self-center">{{
              (order?.payments || [])[0]?.card?.cardNumber
            }}</span>
          </span>
          <span v-if="paymentNumber > 1" class="mt-2 text-th-label-text">
            {{
              $t('pages.orders.detail.headers.payment_more', {
                number: paymentNumber - 1
              })
            }}</span
          >
        </div>
      </el-col>
      <el-col :md="4">
        <div class="flex flex-col">
          <span class="u-table-section-subtitle">{{
            $t('pages.orders.detail.headers.customer')
          }}</span>
          <span
            class="text-th-primary cursor-pointer u-table-section-data"
            @click="$emit('scroll-to', 'customer')"
            >{{ customerName }}</span
          >
        </div>
      </el-col>
      <el-col :md="4">
        <div class="flex flex-col">
          <span class="u-table-section-subtitle">{{
            $t('pages.orders.detail.headers.sales_channel')
          }}</span>
          <span class="u-table-section-data">{{ order.salesChannel }}</span>
        </div>
      </el-col>
      <el-col :md="4">
        <div class="flex flex-col">
          <span class="u-table-section-subtitle">{{
            $t('pages.orders.detail.headers.recurring')
          }}</span>
          <span class="u-table-section-data">{{
            recurringTypes[order.recurring]
          }}</span>
        </div>
      </el-col>
      <el-col :md="4">
        <el-button class="uppercase action-button" plain>
          {{ $t('pages.orders.detail.actions.refund') }}
        </el-button>
        <el-button plain class="action-button" circle>
          <svgicon
            :src="require('@/assets/icons/th-icon-more.svg')"
            :style="{ height: '24px', width: '24px' }"
        /></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mb-8">
      <el-col>
        <span
          class="text-th-primary cursor-pointer"
          @click="$emit('scroll-to', 'transaction')"
        >
          {{ transactionNumber }}
          {{ $t('pages.orders.detail.headers.transactions') }}
        </span>
      </el-col>
    </el-row>
  </th-wrapper>
</template>

<script>
import orderSetup from '../../constants/order'
import originText from '../../components/origin-text'
import originPayment from '../../components/origin-payment.vue'
import statusText from '../../components/status-text'
export default {
  components: {
    originText,
    originPayment,
    statusText
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },

  setup() {
    const orderConst = orderSetup.setup()
    const recurringTypes = {}
    const brands = {}
    orderConst.recurringTypes.forEach((type) => {
      recurringTypes[type.value] = type.label
    })
    orderConst.paymentMethodBrands.forEach((brand) => {
      brands[brand.value] = brand.label
    })
    return {
      recurringTypes,
      brands
    }
  },

  computed: {
    order() {
      return this.modelValue || {}
    },
    transactionNumber() {
      return this.modelValue?.transactions?.length || 0
    },
    paymentNumber() {
      return this.modelValue?.payments?.length || 0
    },
    customerName() {
      return this.order?.customer
        ? [
            this.order?.customer?.firstName,
            this.order?.customer?.lastName
          ].join(' ')
        : '-'
    }
  }
}
</script>
<style scoped>
.action-button {
  color: var(--button-text-color);
  border-color: var(--button-border-color);
}
</style>
