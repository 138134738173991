<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="!transactionIsEmpty"
    :full-width-title="transactionIsEmpty"
  >
    <!-- Title -->
    <template #title>
      <title-text
        :title="$t('pages.order.transaction.subtitle')"
        :no-data="transactionIsEmpty"
      />
    </template>
    <div v-if="!transactionIsEmpty">
      <el-row :gutter="20" class="mb-8">
        <el-col :md="9">
          <div
            v-for="(payment, paymentIndex) in payments"
            :key="paymentIndex"
            class="flex flex-col"
          >
            <div class="u-table-section-subtitle flex cursor-pointer">
              <origin-chevron
                :opened="!collapsed[paymentIndex]"
                @toggle="collapsed[paymentIndex] = !collapsed[paymentIndex]"
              />
              <span
                class="u-body-details u-body-detail-holder mb-0 place-self-center"
                >{{
                  $t('pages.orders.detail.transaction.payment.subtitle') +
                  ' ' +
                  payment.paymentId
                }}</span
              >
            </div>
            <div v-if="!collapsed[paymentIndex]">
              <order-transaction-payment
                v-for="(
                  transaction, transactionIndex
                ) in transactionsPerPayment(payment.paymentId)"
                :key="transactionIndex"
                :transaction="transaction"
                :selected="
                  selectedTransaction.identificationUniqueId ===
                  transaction.identificationUniqueId
                "
                @click="selectedTransaction = transaction"
              />
            </div>
          </div>
        </el-col>
        <el-col :md="15">
          <div class="flex flex-col">
            <order-transaction-detail
              v-if="selectedTransaction"
              :transaction="selectedTransaction"
            />
          </div>
        </el-col>
      </el-row>
    </div>
  </th-wrapper>
</template>

<script>
import orderTransactionPayment from './order-transaction-payment'
import orderTransactionDetail from './order-transaction-detail'
import titleText from '../../components/title-text'
import originChevron from './origin-chevron'

export default {
  components: {
    orderTransactionPayment,
    orderTransactionDetail,
    titleText,
    originChevron
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      selectedTransaction: null,
      collapsed: []
    }
  },

  computed: {
    payments() {
      return this.modelValue?.payments || []
    },
    transactionIsEmpty() {
      return this.payments.length === 0
    }
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(modelValue) {
        // When we reset the order, we select first transaction if available
        this.selectedTransaction = modelValue?.transactions?.length
          ? modelValue.transactions[0]
          : null
      }
    }
  },

  methods: {
    transactionsPerPayment(paymentId) {
      return (
        this.modelValue?.transactions.filter(
          (transaction) => transaction.identificationPaymentId === paymentId
        ) || []
      )
    }
  }
}
</script>
