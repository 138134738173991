<template>
  <div class="p-4">
    <!-- Title -->
    <el-row :gutter="20" class="border-b pb-5 mb-8">
      <el-col :md="17">
        <div class="w-1/2 mb-2 flex">
          <span class="u-title-section">{{
            transactionTypes[transaction?.transactionType] || ''
          }}</span>
          <status-text :status="transaction.processingResult" />
        </div>
        <div class="u-body-details u-body-detail-holder mb-0">
          {{ transaction.requestTimestamp }}
        </div>
      </el-col>
      <el-col :md="7" class="text-right">
        <span class="u-title-section">{{
          transaction?.paymentPresentationAmount?.amount
            ? $formatCurrency(
                transaction?.paymentPresentationAmount?.amount,
                transaction.paymentPresentationCurrency
              )
            : '-'
        }}</span>
      </el-col>
    </el-row>

    <div class="border-b pb-5 mb-8">
      <!-- Channel -->
      <single-row-info
        :info="transaction?.channelName"
        :tag="$t('common.orders.transaction.channel.label')"
      />
      <!-- Recurrence mode -->
      <single-row-info
        :info="transaction?.paymentRecurrenceMode"
        :tag="$t('common.orders.transaction.recurrence_mode.label')"
      />
    </div>

    <div class="border-b pb-5 mb-8">
      <!-- Country of bank -->
      <single-row-info
        :tag="$t('common.orders.transaction.country_of_bank.label')"
        has-slot-info
      >
        <origin-country :origin="transaction?.accountCountry" />
      </single-row-info>
      <!-- Country of IP address -->
      <single-row-info
        :tag="$t('common.orders.transaction.country_of_ip_address.label')"
        has-slot-info
      >
        <origin-country :origin="transaction?.countryIP" />
      </single-row-info>
      <!-- Risk score -->
      <single-row-info
        :info="transaction?.riskScore"
        :tag="$t('common.orders.transaction.risk_score.label')"
      />
    </div>

    <div class="border-b pb-5 mb-8">
      <!-- Source -->
      <single-row-info
        :info="transaction?.source"
        :tag="$t('common.orders.transaction.source.label')"
      />
      <!-- Mode -->
      <single-row-info
        :info="transaction?.mode"
        :tag="$t('common.orders.transaction.mode.label')"
      />
      <!-- Payment Reversal Type -->
      <single-row-info
        :info="transaction?.paymentReversalType"
        :tag="$t('common.orders.transaction.payment_reversal_type.label')"
      />
      <!-- Token provider -->
      <single-row-info
        :info="transaction?.accountTokenProvider"
        :tag="$t('common.orders.transaction.token_provider.label')"
      />
    </div>

    <div class="border-b pb-5 mb-8">
      <el-row>
        <span class="title-small mb-4">{{
          $t(`common.orders.transaction.billing_fees.label`)
        }}</span>
      </el-row>
      <!-- Applied percentage -->
      <single-row-info
        :info="transaction?.billingFee?.percentage"
        :tag="$t('common.orders.transaction.applied_percentage.label')"
      />
      <!-- Amount -->
      <single-row-info
        :info="transaction?.billingFee?.amount"
        :tag="$t('common.orders.transaction.amount.label')"
      />
    </div>

    <div class="border-b pb-5 mb-8">
      <el-row>
        <span class="title-small mb-4">{{
          $t(`common.orders.transaction.shipping_information.label`)
        }}</span>
      </el-row>
      <!-- Delivery tracking id -->
      <single-row-info
        :info="transaction?.shippingTrackingId"
        :tag="$t('common.orders.transaction.delivery_tracking_id.label')"
      />
      <!-- Delivery service -->
      <single-row-info
        :info="transaction?.shippingCarrier"
        :tag="$t('common.orders.transaction.delivery_service.label')"
      />
      <!-- Return tracking id -->
      <single-row-info
        :info="transaction?.shippingInfo?.returnTrackingId"
        :tag="$t('common.orders.transaction.return_tracking_id.label')"
      />
    </div>

    <div class="border-b pb-5 mb-8">
      <el-row :gutter="20" class="w-full mb-5">
        <el-col :md="24">
          <span class="u-subtitle">{{
            $t(`common.orders.transaction.trasaction_ids.label`)
          }}</span>
        </el-col>
      </el-row>
      <single-row-info
        :info="transaction?.identificationRootId"
        :tag="
          $t('common.orders.transaction.detail.identification.root_id.label')
        "
      />
      <single-row-info
        :info="transaction?.identificationTransactionId"
        :tag="
          $t(
            'common.orders.transaction.detail.identification.transaction_id.label'
          )
        "
      />
      <single-row-info
        :info="transaction?.identificationUniqueId"
        :tag="
          $t('common.orders.transaction.detail.identification.unique_id.label')
        "
      />
      <single-row-info
        :info="transaction?.identificationShortId"
        :tag="
          $t('common.orders.transaction.detail.identification.short_id.label')
        "
      />
      <single-row-info
        :info="transaction?.identificationShopperId"
        :tag="
          $t('common.orders.transaction.detail.identification.shopper_id.label')
        "
      />
      <single-row-info
        :info="transaction?.identificationInvoiceId"
        :tag="
          $t('common.orders.transaction.detail.identification.invoice_id.label')
        "
      />
      <single-row-info
        :info="transaction?.identificationReferenceId"
        :tag="
          $t(
            'common.orders.transaction.detail.identification.reference_id.label'
          )
        "
      />
      <single-row-info
        :info="transaction?.identificationContractId"
        :tag="
          $t(
            'common.orders.transaction.detail.identification.contract_id.label'
          )
        "
      />
      <single-row-info
        :info="transaction?.identificationCreditorId"
        :tag="
          $t(
            'common.orders.transaction.detail.identification.creditor_id.label'
          )
        "
      />
      <single-row-info
        :info="transaction?.identificationPnpTxnId"
        :tag="
          $t('common.orders.transaction.detail.identification.pnp_txn_id.label')
        "
      />
      <single-row-info
        :info="transaction?.identificationPnpTxnId2"
        :tag="
          $t(
            'common.orders.transaction.detail.identification.pnp_txn_id_2.label'
          )
        "
      />
      <single-row-info
        :info="transaction?.identificationPnpId"
        :tag="
          $t('common.orders.transaction.detail.identification.pnp_id.label')
        "
      />
      <single-row-info
        :info="transaction?.identificationPnpName"
        :tag="
          $t('common.orders.transaction.detail.identification.pnp_name.label')
        "
      />
      <single-row-info
        :info="transaction?.identificationCutoverId"
        :tag="
          $t('common.orders.transaction.detail.identification.cutover_id.label')
        "
      />
    </div>

    <div class="border-b pb-5 mb-8">
      <el-row>
        <span class="title-small mb-4">{{
          $t(`common.orders.transaction.processing_data.label`)
        }}</span>
      </el-row>
      <!-- Processing status code -->
      <single-row-info
        :info="transaction?.processingStatusCode"
        :tag="$t('common.orders.transaction.processing_status_code.label')"
      />
      <!-- Processing status message -->
      <single-row-info
        :info="transaction?.processingStatusDescription"
        :tag="$t('common.orders.transaction.processing_status_message.label')"
      />
      <!-- Processing status return message -->
      <single-row-info
        :info="transaction?.processingReturnValueDescription"
        :tag="
          $t('common.orders.transaction.processing_status_return_message.label')
        "
      />
      <!-- Processing status return code -->
      <single-row-info
        :info="transaction?.processingReturnValueCode"
        :tag="
          $t('common.orders.transaction.processing_status_return_code.label')
        "
      />
      <!-- Processing status reason -->
      <single-row-info
        :info="transaction?.processingReasonDescription"
        :tag="$t('common.orders.transaction.processing_status_reason.label')"
      />
    </div>

    <div class="border-b pb-5 mb-8">
      <el-row>
        <span class="title-small mb-4">{{
          $t(`common.orders.transaction.threeDS_details.label`)
        }}</span>
      </el-row>
      <!-- Reset indicator -->
      <single-row-info
        :info="transaction?.paymentThreedsChallengeIndicator"
        :tag="$t('common.orders.transaction.reset_indicator.label')"
      />
      <!-- DS transaction ID -->
      <single-row-info
        :info="transaction?.paymentThreedsDsTransactionId"
        :tag="$t('common.orders.transaction.ds_transaction_id.label')"
      />
      <!-- Protocol version -->
      <single-row-info
        :info="transaction?.paymentThreedsVersion"
        :tag="$t('common.orders.transaction.protocol_version.label')"
      />
      <!-- Authentication status -->
      <single-row-info
        :info="transaction?.authenticationResultIndicator"
        :tag="$t('common.orders.transaction.authentication_status.label')"
      />
      <!-- XID -->
      <single-row-info
        :info="transaction?.paymentThreedsXid"
        :tag="$t('common.orders.transaction.xid.label')"
      />
    </div>

    <div class="border-b pb-5 mb-8">
      <el-row>
        <span class="title-small mb-4">{{
          $t(`common.orders.transaction.metadata.label`)
        }}</span>
      </el-row>
      <!-- SDK name -->
      <single-row-info
        :info="transaction?.criteriaMeta?.sdkName"
        :tag="$t('common.orders.transaction.sdk_name.label')"
      />
      <!-- SDK  version -->
      <single-row-info
        :info="transaction?.criteriaMeta?.sdkVersion"
        :tag="$t('common.orders.transaction.sdk_version.label')"
      />
    </div>
  </div>
</template>

<script>
import statusText from '../../components/status-text'
import originCountry from '../../components/origin-country'
import orderSetup from '../../constants/order'
import singleRowInfo from '../../components/single-row-info.vue'
export default {
  components: {
    statusText,
    originCountry,
    singleRowInfo
  },
  props: {
    transaction: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const orderConst = orderSetup.setup()
    const transactionTypes = {}
    orderConst.transactionTypes.forEach((type) => {
      transactionTypes[type.value] = type.label
    })
    return {
      transactionTypes
    }
  },
  computed: {
    notDefined() {
      return this.$t('common.orders.not_defined')
    }
  }
}
</script>
<style scoped>
.title-small {
  color: var(--text-default-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: left;
}
</style>
