<template>
  <th-page-wrapper v-loading="loading">
    <order-general
      ref="general"
      v-model="order"
      class="m-8"
      @scroll-to="scrollTo($event)"
    />
    <order-basket ref="basket" v-model="order" class="m-8" />
    <order-customer ref="customer" v-model="order" class="m-8" />
    <order-payment ref="payment" v-model="order" class="m-8" />
    <order-transaction ref="transaction" v-model="order" class="m-8" />
    <order-attachment ref="attachment" v-model="order" class="m-8" />
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import OrderGeneral from './components/order-general'
import OrderBasket from './components/order-basket'
import OrderCustomer from '../components/order-customer'
import OrderPayment from './components/order-payment'
import OrderTransaction from './components/order-transaction'
import OrderAttachment from './components/order-attachment'

export default {
  name: 'ReportsOrdersDetail',
  metaInfo() {
    return {
      title: this.$t('pages.orders.title')
    }
  },
  components: {
    OrderGeneral,
    OrderBasket,
    OrderTransaction,
    OrderCustomer,
    OrderAttachment,
    OrderPayment
  },
  data() {
    return {
      order: null
    }
  },
  computed: {
    orderId() {
      return this.$route.params.id
    }
  },
  async mounted() {
    this.fetch(this.orderId)
  },
  methods: {
    async fetch(id) {
      try {
        this.loading = true
        const { data = {} } = await th.ordersV2().get(id)
        this.order = this.formatOrder(data)
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.order.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },
    formatOrder(order) {
      if (order?.basket?.items) {
        order.basket.totalGrossValue = order.basket.discount = order.basket.total = order.basket.tax = order.basket.quantity = 0
        order?.basket?.items?.forEach((item) => {
          order.basket.totalGrossValue += item.amountPerUnit || 0
          order.basket.discount += item.discount || 0
          order.basket.total += item.totalAmount || 0
          order.basket.tax += item.tax || 0
          order.basket.quantity += item.quantity || 0
        })
      }
      return order
    },
    scrollTo($event) {
      const element = this.$refs[$event]
      if (element?.$el?.scrollIntoView) {
        element.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        })
      }
    }
  }
}
</script>
