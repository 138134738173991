<template>
  <el-button
    :title="
      opened
        ? $t('components.th_wrapper.expand')
        : $t('components.th_wrapper.collapse')
    "
    class="collapse-title pr-2 pl-0 h-3 place-self-center"
    text
    @click="$emit('toggle')"
  >
    <svgicon
      :src="require('@/assets/icons/th-icon-chevron-down.svg')"
      :style="{
        height: '25px',
        width: '25px',
        color: '#A7ABB1'
      }"
      class="transition-all duration-300 fill-current -rotate-90"
      :class="{ 'transform -rotate-180': !opened }"
    />
  </el-button>
</template>
<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  }
}
</script>
