<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="!paymentIsEmpty"
    :full-width-title="paymentIsEmpty"
  >
    <template #title>
      <title-text
        :title="$t('pages.order.payment.subtitle')"
        :no-data="paymentIsEmpty"
      />
    </template>
    <div v-if="!paymentIsEmpty">
      <div
        v-for="(payment, paymentIndex) in payments"
        :key="paymentIndex"
        class="py-4 payment-segment"
      >
        <el-row :gutter="20" class="font-bold">
          <el-col :md="1" class="text-right cursor-pointer mt-2">
            <origin-chevron
              :opened="collapsed[paymentIndex]"
              data-testid="payment-expand"
              @toggle="collapsed[paymentIndex] = !collapsed[paymentIndex]"
            />
          </el-col>
          <el-col :md="11" class="payment-header">
            {{ $t('pages.order.payment.singular.label') }}
            {{ payment.paymentId }}
          </el-col>
          <el-col :md="2" class="text-right payment-header">
            {{ $formatCurrency(payment.balanceNumber, order.currency) }}
          </el-col>
          <el-col :md="5" class="text-right payment-header">
            {{ payment?.card?.clearingDate }}
          </el-col>
          <el-col :md="2" class="text-right">
            <origin-payment :origin="payment.brand" />
          </el-col>
          <el-col :md="3" class="text-center">
            <origin-text :origin="order.origin" />
          </el-col>
        </el-row>
        <Transition>
          <el-row v-if="collapsed[paymentIndex]" :gutter="20" class="mt-8">
            <el-col :md="12" class="pl-12">
              <single-row-info
                :tag="$t('pages.order.payment.paymentMethod')"
                has-slot-info
              >
                <div class="flex">
                  <origin-payment
                    :origin="payment.brand"
                    class="card-detail-position"
                  />
                  <span class="u-body-details">{{
                    getPaymentMethod(payment)
                  }}</span>
                </div>
              </single-row-info>

              <single-row-info
                :info="payment?.card?.cardNumber"
                :tag="variableTag(payment.paymentMethod, 'Number')"
              />

              <single-row-info
                v-if="
                  payment?.paymentMethod &&
                  payment.paymentMethod.toLowerCase() === 'card'
                "
                :info="payment?.card?.expiryDate"
                :tag="$t('pages.order.payment.expiry')"
              />

              <single-row-info
                :info="payment?.card?.cardHolder"
                :tag="variableTag(payment.paymentMethod, 'Holder')"
              />

              <single-row-info
                :info="payment?.card?.bankName"
                :tag="$t('pages.order.payment.bank')"
              />

              <single-row-info
                :info="payment?.card?.bic"
                :tag="$t('pages.order.payment.bic')"
              />

              <single-row-info
                :tag="$t('pages.order.payment.countryBank')"
                has-slot-info
              >
                <origin-country :origin="mappedCountry(payment.paymentId)" />
              </single-row-info>
            </el-col>

            <el-col :md="12" class="pl-12">
              <single-row-info
                :info="payment?.type"
                :tag="$t('pages.order.payment.paymentType')"
              />
              <single-row-info
                v-if="payment?.paymentMethod && payment?.description"
                :info="
                  payment?.paymentMethod +
                  $t('pages.order.payment.paymentFor') +
                  payment?.description
                "
                :tag="$t('pages.order.payment.usage')"
              />
              <single-row-info
                :info="payment?.description"
                :tag="$t('pages.order.payment.descriptor')"
              />
            </el-col>
          </el-row>
        </Transition>
      </div>
    </div>
  </th-wrapper>
</template>
<script>
import originText from '../../components/origin-text'
import titleText from '../../components/title-text'
import originPayment from '../../components/origin-payment'
import originCountry from '../../components/origin-country'
import originChevron from './origin-chevron'
import singleRowInfo from '../../components/single-row-info.vue'

export default {
  components: {
    originText,
    titleText,
    originPayment,
    originCountry,
    originChevron,
    singleRowInfo
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      collapsed: []
    }
  },
  computed: {
    order() {
      return this.modelValue || {}
    },
    payments() {
      return this.modelValue?.payments || []
    },
    customer() {
      return this.modelValue?.customer || []
    },
    transactions() {
      return this.modelValue?.transactions || []
    },
    paymentIsEmpty() {
      return (this.modelValue?.payments || []).length === 0
    }
  },
  methods: {
    variableTag(method, type) {
      let account = method.toLowerCase() === 'card' ? 'card' : 'account'
      let tag = account + type
      return this.$t(`pages.order.payment.${tag}`)
    },
    getPaymentMethod(payment) {
      return payment.paymentMethod.toLowerCase() === 'card'
        ? payment.brand
        : payment.paymentMethod
    },
    mappedCountry(id) {
      let mappedTransaction = this.transactions.find(
        (obj) => obj.identificationPaymentId === id
      )
      return mappedTransaction?.accountCountry
    }
  }
}
</script>
<style scoped>
.payment-segment {
  border-top: 1px solid #f1f1f3;
}

.payment-segment:last-child {
  border-bottom: 1px solid #f1f1f3;
}

.payment-header {
  font-size: 14px;
  margin-top: 6px;
  letter-spacing: var(--letter-spacing-titles);
  color: var(--font-color);
}

.payment-details > span {
  font-size: 14px;
  margin-bottom: 10px;
  letter-spacing: var(--letter-spacing-table);
  line-height: 20px;
  color: var(--font-color);
}

.payment-details > * {
  font-size: 14px;
  letter-spacing: var(--letter-spacing-table);
}

.card-detail-position {
  position: relative;
  top: -4px;
  margin-right: 8px;
  margin-left: 0 !important;
  max-width: 34px;
}
</style>
